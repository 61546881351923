.contact_hero_text {
  padding: 70px 0 50px 25px;
}
.contact_hero_text p {
  font-size: 24px;
  color: #fff;
}
.contact_hero_text p a {
  color: #febc35;
}
.contact_hero_text h1 {
  color: #fff;
  margin-top: 25px;
  font-size: 100px;
  font-weight: bold;
}

@media (max-width: 800px) {
  .contact_hero_text h1 {
    font-size: 70px;
  }
  .contact_hero_text p {
    font-size: 22px;
  }
}
