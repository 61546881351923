.footer_container {
  background-color: #000;
  padding: 50px 0px;
}
.footer_social_icons {
  display: flex;
  justify-content: space-between;
}
.footer_title {
  font-size: 30px;
  color: #fff;
  margin-bottom: 35px;
}
.footer_about_text p {
  color: #cacaca;
  letter-spacing: 1px;
  margin: 30px 0;
}
.footer_icons_box {
  background-color: #0f0f0f;
  border-radius: 10px;
  height: 60px;
  width: 60px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.footer_icons_box:hover {
  background-color: #febc35;
}
.footer_icons_box i {
  margin: 0;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.footer_news_content {
  display: flex;
  padding: 10px 0;
}
.footer_img img {
  width: 90px;
  height: 90px;
  border-radius: 10px;
}
.footer_news_time {
  color: #777;
  font-size: 15px;
  margin-bottom: 5px;
}
.footer_news_desc {
  color: #fff;
  font-size: 18px;
}
.footer_news_text {
  margin-left: 25px;
}
.footer_newsletter_text p {
  color: #cacaca;
}
.footer_call_box {
  background-color: #febc35;
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 10px;
  cursor: pointer;
}
.footer_call_box:hover {
  background-color: #0f0f0f;
}
.footer_call_box i {
  position: absolute;
  margin: 0;
  padding: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
.footer_bottom {
  background-color: #0f0f0f;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer_bottom p {
  color: #cacaca;
  font-size: 21px;
}
.footer_bottom img {
  width: 220px;
}
/* .pd_0 {
  padding: 0 !important;
  margin: 0;
} */
.footer_form input {
  padding: 11px 18px!important;
  margin-top: 20px;
}
.footer_news_letter{
  padding:12px;
  background-color: #febc35;
  color: #fff;
  border: none;
}
@media (max-width: 800px) {
  .footer_form {
    justify-content: space-around;
    /* margin-top: 20px; */
    align-items: center;
  }
  .footer_form input {
    padding: 16px !important;
  }
  .btn_newsletter i {
    font-size: 22px;
    padding: 12px;
  }
  .footer_title {
    margin-top: 25px;
  }
  .footer_bottom {
    display: block;
    text-align: center;
  }
  .footer_bottom p {
    margin-bottom: 10px;
  }
 
}
@media (max-width: 700px) {
.footer_news_letter{
  padding:17px 18px;
  margin-top: 20px;
}
}
