.callus_container {
  margin: 35px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(rgba(254, 188, 53, 0.8), rgba(254, 188, 53, 0.8)),
    url("../Assets//callus.jpg");
  background-position: center;
  background-size: cover;
  justify-content: space-around;
  display: flex;
  align-items: center;
  padding: 25px 0;
}

.callus_title {
  color: #000;
  margin: 0;
  padding: 15px;
  font-weight: bold;
}
.callus_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 250, 0, 0.8), url("../Assets/callus.jpg");
}
.call_container {
  display: flex;
  align-items: center;
}
.call_circle {
  background-color: #fff;
  border-radius: 50%;
  padding: 15px;
  align-items: center;
  text-align: center;
  height: 50px;
  width: 50px;
  margin: 20px;
}
.call_us h2 {
  font-size: 25px;
  font-weight: bold;
  color: #000;
}
.call_us p {
  color: #000;
  font-size: 18px;
}
.call_circle i {
  color: #000;
}
h2 {
  margin: 0;
}
p {
  margin: 0;
}
.margin_top_1002 {
  margin-top: 100px;
}
@media (max-width: 800px) {
  .callus_container {
    display: block;
    text-align: center;
    margin-left: 0;
    margin-right: 0;
  }
  .call_container {
    text-align: center !important;
  }
}
