.servicep_card {
  box-shadow: 0 16px 32px 0 rgb(0 0 0 / 5%);
  padding: 15px;
  margin-bottom: 50px;
}
.service_detail_left_heading {
  font-weight: bold;
  margin-top: 50px !important;
}
.servicep_text {
  margin: 25px 0;
}
.servicep_text h4 {
  font-weight: bold;
  font-size: 20px;
}
.servicep_text p {
  color: #777;
  margin-bottom: 25px;
}
.service_readmore {
  transition: 0.3s;
  font-weight: bold;
}
.service_readmore:hover {
  color: #febc35;
  padding-left: 15px;
}
.service_detail_left_box {
  background-color: #febc35;
  padding: 15px;
  margin: 15px 0;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
}
.service_detail_left_box:hover {
  background-color: #001659;
}
.service_detail_left_box:hover p {
  color: #fff;
}
.service_detail_left_box p {
  font-weight: 500;
}

.download {
  border-radius: 8px !important;
  padding: 15px 18px !important;
  background-color: #000;
  color: #fff;
}
.blue {
  background-color: #001659;
}
.service_detail_left_para_box {
  display: flex;
  margin-top: 25px;
}
.service_detail_left_para_box .service_detail_left_para {
  margin-left: 25px;
}
.service_detail_left_para {
  color: #777;
}
.service_detail_left_icon i {
  color: #777;
}
.left_box {
  margin: 0 0 50px 0;
}
.service_detail_left_phone {
  font-weight: bold;
  color: #001659;
  margin-top: 5px;
}
.space {
  margin: 25px 0;
}
.space h2 {
  font-weight: bold;
}
.space p {
  margin-top: 15px;
  color: #777;
}
.service_detail_banner {
  background-color: #001659;
  padding: 35px;
  margin: 25px 0;
}
.service_detail_banner p {
  color: #fff;
  font-size: 23px;
}
