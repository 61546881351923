.pricing_card {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../Assets/serviceBigImg3.jpg");
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 600px;
  background-position: center;
  background-size: cover;
  padding: 45px 40px;
  position: relative;
  margin-bottom: 50px;
}
.pricing_price {
  font-size: 75px;
  font-weight: bold;
  color: #febc35;
}
.pricing_price span {
  font-size: 18px;
}
.pricing_title {
  color: #fff;
  font-size: 25px;
  font-weight: bold;
}
.pricing_sub_title {
  color: #dcdcdc;
  margin-bottom: 25px;
}
.pricing_features p {
  color: #fff;
  margin: 10px 0;
}
.pricing_btn {
  align-self: center;
  bottom: 30px;
  position: absolute;
}
.fa-angles-right {
  color: #febc35;
}
