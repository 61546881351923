.modal_container {
  background-color: #fff;
  height: 500px;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  justify-content: center;
  overflow-y: scroll;
  border-radius: 5px;
}
.contact_form input {
  padding: 15px!important;
}
.val_msg {
  text-align: left;
}

.modal_container::-webkit-scrollbar {
  width: 10px;
}
.modal_container::-webkit-scrollbar-thumb {
  background-color: #febc35;
  outline: 1px solid #febc35;
}
@media (max-width: 800px) {
  .modal_container {
    height: 500px;
    width: 380px;
  }
}
