.skill_box {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../Assets/Services/about_big.png");
  background-position: center;
  background-size: cover;
  padding: 100px 0;
}
.align {
  align-items: center;
}
.skill_text {
  padding-left: 25px;
  margin-top: 25px;
}
.bars {
  padding-left: 25px;
}
.skill,
.skill p {
  color: #fff;
}

.bars {
  color: #fff;
}
.bar_title {
  font-weight: bold;
}
.bar_bg {
  text-align: end;
  width: 90%;
  border-radius: 25px;
  background-color: #fff;
  margin: 5px 0;
}
.bar {
  margin: 20px 0;
}
.bar_per {
  background-color: #febc35;
  border-radius: 25px;
  padding-right: 25px;
  font-size: 13px;
  color: #000;
}
.bar_per1 {
  width: 95%;
}
.bar_per2 {
  width: 85%;
}
.bar_per3 {
  width: 75%;
}
@media (max-width: 800px) {
  .skill .exp_title {
    font-size: 35px;
  }
}
