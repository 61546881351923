.exp_left_container {
  padding: 0 20px;
  margin-top: 35px;
}
.exp_right_container {
  padding: 0 20px;
}
.analytics_profile {
  display: flex;
  margin-top: 25px;
}
.analytics_profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.analytics_profile_text {
  margin-left: 25px;
}
.analytics_profile_text p {
  font-weight: bold;
  color: #000;
}
.analytics_profile_text span {
  color: #777;
}
.exp_desc {
  padding-bottom: 30px;
}
