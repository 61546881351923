.navbar {
  background-color: #000;
  border-radius: 8px;
  padding: 15px 0;
  align-items: center;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1) !important;
  display: flex;
  margin: 0 35px;
  top: -20px;
  position: relative;
  z-index: 99;
}
.nav_links ul li a {
  padding: 0 15px;
  font-size: 20px;
  font-weight: 500;
  transition: all 0.3s ease-out;
  color: #fff;
}
.nav_links ul li a:hover {
  color: #febc35;
}
.search_container {
  margin-right: 25px;
  background-color: #febc35;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}
.search_container:hover {
  background-color: #000;
  color: #fff;
}
.nav_links ul li a.active {
  color: #febc35;
}
.open {
  background-color: #000;
  border-radius: 8px;
  padding: 15px 0;
  align-items: center;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1) !important;
  display: flex;
  justify-content: space-between;
  margin: 0 35px;
  top: -20px;
  position: relative;
}
@media only screen and (max-width: 1000px) {
  .navbar {
    display: none;
    height: 100vh;
    background-color: #000;
  }
  .open ul li a {
    display: block;
    padding: 15px 0;
    color: #fff;
  }
  .navbar ul li a {
    display: block;
    padding: 15px 0;
    color: #fff;
  }
  .search_container {
    margin-left: 15px;
    display: none;
  }
}
