body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Noto Sans", sans-serif;
  overflow-x: hidden;
}
img {
  width: 100%;
}
a {
  text-decoration: none;
  color: #000;
}
a:hover {
  color: #000;
}
ul {
  margin: 0;
}
li {
  list-style: none;
}
