.contact_container {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../Assets/Services/contact_bg.jpg");
  height: 100%;
  background-position: center;
  background-size: cover;
  position: relative;
  top: -45px;
  z-index: -1;
}
.contact_card {
  background-color: #f8f8f8;
  padding: 50px 40px;
  margin-bottom: 45px;
  transition: all 0.3s ease-in-out;
}
.contact_card:hover {
  background-color: #000;
}
.contact_card:hover h4,
.contact_card:hover .contact_circle i {
  color: #fff !important;
}
.contact_card:hover .contact_circle {
  background-color: #febc35;
}
.contact_card_top {
  display: flex;
  margin-bottom: 25px;
}
.contact_circle {
  background-color: #fff;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  position: relative;
}
.contact_circle i {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #febc35;
}
.title_box {
  margin-left: 30px;
}
.title_box h4 {
  font-size: 27px;
  font-weight: bold;
}
.title_box p {
  color: #777;
  font-size: 18px;
}
.contact_card_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.circle_white {
  background-color: #fff;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  position: relative;
}
.circle_white i {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
}
.contact_bottom_left p {
  color: #777;
  font-size: 18px;
}
.contact_form_container {
  margin-bottom: 25px;
}
.contact_form input,
textarea {
  width: 100%;
}
.contact_form label {
  font-size: 18px;
  font-weight: 500;
  margin-top: 30px;
}
.contact_form input,
textarea {
  border: none;
  background-color: #f8f8f8;
  padding: 25px;
  margin-top: 8px;
}
.contact_form input:focus,
textarea:focus {
  outline: none;
}
textarea {
  margin: 0;
}
.contact_btn {
  border-radius: 0 !important;
  margin-top: 25px;
  background-color: #febc35 !important;
  padding: 12px 22px !important;
  width: 30% !important;
}
.required {
  color: #c81c1c;
}
.error_label {
  display: flex;
}
.val_msg {
  margin-top: 10px;
  color: #c81c1c;
  font-weight: bold;
}
.btn:focus {
  outline: none !important;
}
@media (max-width: 800px) {
  .contact_form input,
  textarea {
    padding: 15px;
  }
  .contact_form label {
    font-size: 16px;
  }
  .service_title {
    font-size: 40px;
  }
  .contact_btn {
    width: 100% !important;
  }
}
