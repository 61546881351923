.newsletter_right_content {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../Assets/services-6_1.jpg");
  background-position: center;
  background-size: cover;
  height: 100%;
  position: relative;
}
.newsletter_text {
  text-align: center;
  padding-top: 20%;
}
.newsletter_text h1 {
  color: #fff;
  font-weight: bold;
  font-size: 60px;
}
.newsletter_text p {
  font-size: 21px;
  font-weight: 500;
  color: #fff;
}
.newsletter_form {
  padding: 30px 0;
  position: relative;
  display: flex;
  justify-content: center;
}
.newsletter_form input {
  padding: 14px 22px;
  border: 1px solid #f7f7f7;
  border-radius: 5px 0 0 5px;
}
.newsletter_form input:focus {
  outline: none;
}
.btn_newsletter {
  border-radius: 0 5px 5px 0 !important;
}
.pd_0_r {
  padding-right: 0;
}
.pd_0_l {
  padding-left: 0;
  position: relative;
}
@media (max-width: 800px) {
  .newsletter_right_content {
    border-radius: 10px;
  }
  .pd_0_l {
    padding: 0 20px;
  }
  .pd_0_r {
    padding: 0px;
    display: none;
  }

  .newsletter_form input {
    padding: 14px;
    border: 1px solid #f7f7f7;
    border-radius: 5px 0 0 5px;
  }
  .btn_newsletter {
    border-radius: 0 5px 5px 0 !important;
  }
  .newsletter_text h1 {
    font-size: 40px;
  }
  .newsletter_text p {
    font-size: 18px;
  }
  .newsletter_form input {
    padding: 4px !important;
    padding: 0;
    border: 1px solid #f7f7f7;
    border-radius: 5px 0 0 5px;
  }
  .btn_newsletter {
    padding: 4px !important;
    font-size: 17px !important;
  }
 
}
