.blog_detail_content {
  border: 2px solid #ededed;
  padding: 35px;
}
.blog_detail_title {
  margin-top: 15px;
  font-weight: bold;
}
.blog_detail_subinfo {
  display: flex;
  margin-top: 25px;
}
.blog_detail_subinfo p {
  margin-right: 35px;
}
.blog_detail_desc {
  margin-top: 25px;
}
.blog_detail_desc p {
  color: #777;
}
.blog_detail_img {
  margin-bottom: 25px;
}
.banner_desc {
  background-color: #001659;
  padding: 50px;
  margin-top: 25px;
}
.banner_desc p {
  color: #fff;
  font-size: 23px;
}
.blog_detail_about {
  border: 2px solid #ededed;
  text-align: center;
}
.blog_detail_popular_feeds {
  text-align: start;
}

.about_img img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  margin: 25px 0;
}
.about {
  display: flex;
  align-items: center;
}
.blog_detail_button {
  background-color: #ff5e14 !important;
}
.about i {
  color: #ff5e14;
}
.blog_detail_about_title {
  margin-left: 25px;
  margin-bottom: 0;
  font-weight: bold;
}
.blog_detail_about_text {
  padding: 25px;
}
.author_desc {
  margin: 15px 0;
  line-height: 35px;
  color: #777;
}
.author_social_icons a {
  margin: 0 15px 0 0;
  font-size: 23px;
}
.feed_img {
  border-radius: 50%;
  height: 80px;
  width: 80px;
}
.feed_container {
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.feed_text {
  margin-left: 25px;
}
.feed_text h6 {
  font-weight: bold;
}
.blog_detail_button {
  border-radius: 0 !important;
  padding: 8px 13px !important;
  color: #fff !important;
}
@media (max-width: 800px) {
  .blog_detail_content {
    border: 2px solid #ededed;
    padding: 35px 15px 35px 15px;
  }
  /* .blog_detail_subinfo p {
    margin-right: 0px;
  } */
}
