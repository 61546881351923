.img_container {
  margin-top: 25px;
  overflow: hidden;
}
.img_container:hover img {
  transform: scale(1.2);
}
.img_container img {
  width: 200px;
  border: 2px solid #cacaca;
  height: 100px;
  padding: 15px;
  transition: 0.3s;
  cursor: pointer;
}
@media (max-width: 1000px) {
  .img_container img {
    width: 80%;
  }
}
