.header_container {
  display: flex;
  justify-content: space-between;
  background-color: #fcfcfc;
  padding: 30px;
}
.element_text {
  font-weight: bold;
}
.single_element span {
  color: #777;
  font-size: 19px;
}
.btn {
  background-color: #febc35;
  color: #000;
  font-size: 19px;
  padding: 12px 22px;
  font-weight: bold;
  border-radius: 8px;
  transition: all 0.3s;
  cursor: pointer;
}
.btn:hover {
  background-color: #000;
  color: #f8f8f8;
}
.menu_icon {
  display: none;
  position: relative;
  top: 15px;
}
.hamburger {
  font-size: 27px;
  cursor: pointer;
}
@media (max-width: 1000px) {
  .btn {
    background-color: #febc35;
    color: #000;
    font-size: 15px;
    padding: 3px;
    margin-top: 15px;
    margin-right: 5px;
    margin-left: 5px;
    font-weight: bold;
    border-radius: 8px;
    transition: all 0.3s;
    cursor: pointer;
  }
  .header_btn{
    display: none;
  }
  .element_container{
    display: none;
  }
  .header_container{
    padding-left:5px;
  }
  .menu_icon {
    display: block;
  }
}
