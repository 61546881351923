.process_container {
  margin: 100px 0;
}
.process_left {
  margin-bottom: 50px;
  position: relative;
  box-shadow: 10px 16px 60px 0 rgb(0 0 0 / 10%);
}
.up_image {
  width: 180px;
  height: 180px;
  position: absolute;
  left: -20px;
  top: -30px;
}
.bottom_img {
  width: 180px;
  height: 180px;
  position: absolute;
  right: -20px;
  bottom: -30px;
}
.pd_70 {
  padding-left: 70px;
}
.process_detail_container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.process_number {
  padding-right: 30px;
}
.process_number h1 {
  font-size: 100px;
  -webkit-text-stroke-color: #ffc54f;
  -webkit-text-stroke-width: 1px;
  color: transparent;
  font-weight: bold;
}
.process_text h4 {
  font-weight: bold;
}
.process_text p {
  color: #777;
}
@media (max-width: 762px) {
  .pd_70 {
    padding-left: 0;
  }
}
