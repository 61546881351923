.accordion_container {
  margin: 25px 0;
}
.ques_ans_box {
  background-color: #fff;
  box-shadow: 0 16px 32px 0 rgb(0 0 0 / 5%);
  padding: 20px;
}
.ques_box {
  display: flex;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
}
.ques_box:hover .ques,
.ques_box:hover i {
  color: #febc35;
  transition: all 0.3s ease-in-out;
}
.ques {
  font-size: 18px;
  margin: 0;
  font-weight: bold;
}
.ans {
  padding: 10px 0;
  color: #777;
  display: none;
}
.ansopen {
  padding: 10px 0;
  color: #777;
  display: block;
  font-size: 19px;
}

@media (max-width: 800px) {
  .ques {
    font-size: 16px;
  }
  .ansopen {
    font-size: 16px;
  }
}
