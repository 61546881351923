.home_container {
  background-image: url("../Assets/headerImage.jpg");
  width: 100%;
  background-position: center;
  background-size: cover;
  top: -45px;
  /* z-index: -1; */
  padding: 100px 25px;
  position: relative;
}
.hero_text {
  margin-left: 125px;
}
.hero_title {
  color: #fff;
  font-size: 90px;
  font-weight: bold;
  letter-spacing: 3px;
}
.hero_btns {
  margin-top: 50px;
  margin-left: 10%;
  background-color: "red";
  z-index: 999;
}
.black_bg {
  background-color: #000;
  color: #fff;
  margin-left: 20px;
}
@media (max-width: 800px) {
  .hero_text {
    top: 35%;
    text-align: center;
    margin: 0;
  }
  .hero_title {
    font-size: 55px;
    line-height: 60px;
  }
  .hero_btns {
    top: 40%;
    display: flex;
    text-align: center;
  }
  .hero_btn {
    padding: 12px !important;
    margin: 0 5px;
  }
  .home_container {
    text-align: center;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
  }
  .black_bg {
    margin: 0;
  }
}
