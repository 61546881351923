.about_container1 {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../Assets/about_header.jpg");
  height: 100%;
  background-position: center;
  background-size: cover;
  position: relative;
  top: -45px;
  z-index: -1;
}
.about_container {
  margin-bottom: 30px;
}
.about_content {
  padding-left: 65px;
}
.about_info {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.about_circle {
  position: relative;
  background-color: #febc35;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  padding: 35px;
}
.about_circle i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 35px;
  color: #fff;
}
.about_info_text {
  margin-left: 35px;
}
.about_info_text h3 {
  font-weight: bold;
}
.approach_container {
  margin: 100px 0;
}
.approach_card {
  overflow: hidden;
  background-color: #fff;
  margin-top: 25px;
  box-shadow: 0 30px 60px 0 rgb(52 52 52 / 4%);
}
.approach_card_text {
  padding: 25px;
}
.approach_card_text h4 {
  font-size: 28px;
  transition: 0.3s;
  font-weight: bold;
}
.approach_card_text h4:hover {
  color: #febc35;
}
.approach_card_text a {
  transition: 0.3s;
}
.approach_card_text a:hover {
  color: #febc35;
}
.approach_card_text p {
  color: #777;
  margin-bottom: 15px;
}
.table-container {
  overflow-x: auto;
  margin: 20px;
}

.responsive-table {
  border-collapse: collapse;
  width: 100%;
}

.responsive-table th {
  border: 1px solid #fff;
  padding: 20px;
  text-align: left;
  color: #ffff;

}
.responsive-table td{
  border: 1px solid #febc35;
  padding: 14px;
}

.responsive-table th {
  background-color: #000;
}

@media (max-width: 800px) {
  .about_content {
    padding: 0;
    margin-top: 25px;
  }
}
