.service_container {
  cursor: pointer;
  overflow: hidden;
}
.card:hover .circle {
  background-color: #dfac46;
}
/* .card:hover .service_card_title {
  color: #fff;
} */

.service_content {
  text-align: center;
}
.service_sub_title {
  color: #777;
  font-size: 20px;
}
.service_title {
  font-size: 60px;
  font-weight: bold;
}
.card:hover {
  background: rgba( rgba(0, 0, 0, 0.1));
    /* url("../Assets/serviceBigImg1.jpg"); */
  background-position: center;
  background-size: cover;
}
.card {
  background: rgba(rgba(0, 0, 0, 0.5));
    /* url("../Assets/serviceBigImg1.jpg"); */
  background-position: center;
  background-size: cover;
  position: relative;
  margin: 4px;
  text-align: center;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #fff;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.circle {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffc54f;
  border-radius: 50%;
  padding: 25px;
  transition: all 0.2s ease-in;
  z-index: 88;
}
.service_card_layer {
  background-color: #fff;
  width: 100%;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.service_card_title {
  color: #000;
  margin-top: 200px;
  font-weight: 500;
  margin: 200px 15px 15px 15px;
  z-index: 3;
  padding: 10px 0;
  font-size: 35px;
}
.scontainer {
  padding-right: 35px !important;
  padding-left: 35px !important;
  margin-right: auto;
  margin-left: auto;
}
.margin_top_1001 {
  margin-top: 100px;
}
.service_btn {
  margin-bottom: 25px;
}
